import React from "react";
import VideoPlayer from "./UI/VideoPlayer/VideoPlayer";

function Video() {
	return (
		<section className="video container">
			<div id="video" className="video__content">
				<VideoPlayer />
			</div>
		</section>
	);
}

export default Video;
