import React, { useRef, useState } from "react";

import EmailPNG from "../../assets/img/social/email.png";
import WhatsAppSVG from "../../assets/img/social/whatsapp.svg";
import ButtonPrimary from "./../UI/Buttons/ButtonPrimary";
import Input from "./../UI/Input/Input";
import Textarea from "./../UI/Textarea/Textarea";

function FeedbackForm() {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [message, setMessage] = useState("");
	const [name, setName] = useState("");
	const [isChecked, setIsChecked] = useState(true);
	const [errForm, setErrForm] = useState(false);

	const handleChange = e => {
		const target = e.target;

		setPhoneNumber(target.value);
	};

	const handleChangeName = e => {
		const target = e.target;

		setName(target.value);
	};

	const handleChangeMessage = e => {
		const target = e.target;

		setMessage(target.value);
	};

	const selectFirst = e => {
		const target = e.target.closest(".form-feedback__social-btn");

		target.classList.add("_active");
		target.nextElementSibling.classList.remove("_active");
	};

	const selectSecond = e => {
		const target = e.target.closest(".form-feedback__social-btn");

		target.classList.add("_active");
		target.previousElementSibling.classList.remove("_active");
	};

	const validateFormData = formData => {
		const errors = {};

		// Проверка имени
		if (!formData.name.trim()) {
			errors.name = "Name ist erforderlich";
			const input = document.querySelector(".form-feedback__input._name");
			input.classList.add("error");

			setTimeout(() => {
				input.classList.remove("error");
			}, 3000);
		}

		// Проверка телефонного номера или Instagram
		if (!formData.phoneNumber.trim()) {
			errors.phoneNumber = "Telefonnummer oder Instagram ist erforderlich";
			const input = document.querySelector(
				".form-feedback__input._phone-number"
			);
			input.classList.add("error");

			setTimeout(() => {
				input.classList.remove("error");
			}, 3000);
		}

		// Проверка телефонного номера или Instagram
		if (!formData.message.trim()) {
			errors.message = "Die Nachricht ist erforderlich";
			const textarea = document.querySelector(".form-feedback__textarea");
			textarea.classList.add("error");

			setTimeout(() => {
				textarea.classList.remove("error");
			}, 3000);
		}

		// Проверка чекбокса согласия
		if (!formData.isChecked) {
			setIsChecked(true);
		}

		return errors;
	};

	const handleError = () => {
		const submit = document.querySelector(".form-feedback__submit");
		const submitSpan = document.querySelector(".form-feedback__submit span");

		setErrForm(true);
		submitSpan.textContent =
			"Daten wurden NICHT gesendet! Versuchen Sie später noch einmal";
		submit.disabled = true;
	};

	const sendData = event => {
		event.preventDefault();

		const formData = {
			name: event.target.elements["name"].value,
			phoneNumber: event.target.elements["phone-number"].value,
			message: event.target.elements["message"].value,
			contactType: document.querySelector(".form-feedback__social-btn._active")
				.value,
		};

		const errors = validateFormData(formData);

		if (Object.keys(errors).length === 0) {
			const submit = document.querySelector(".form-feedback__submit");
			const submitSpan = document.querySelector(".form-feedback__submit span");

			fetch("https://www.hds-nrw.online/send_email.php", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			})
				.then(response => response.json())
				.then(data => {
					if (data.status === "error") {
						handleError();
					} else {
						if (!errForm) {
							submitSpan.textContent = "Daten erfolgreich gesendet!";
							submit.classList.add("_success");

							setTimeout(() => {
								setName("");
								setPhoneNumber("");
								setMessage("");
								submitSpan.textContent = "Holen Sie sich ein Angebot ein";
								submit.classList.remove("_success");
							}, 2500);
						}
					}
				})
				.catch(error => {
					handleError();
				});
		} else {
			// Вывод ошибок в консоль или установка состояния ошибок
		}
	};

	return (
		<form name="feedback" className="form-feedback__form" onSubmit={sendData}>
			<div className="form-feedback__social-btns">
				<button
					value="E-Mail"
					type="button"
					onClick={selectFirst}
					className="btn form-feedback__social-btn email"
				>
					<img src={EmailPNG} alt="Email" />
				</button>
				<button
					value="WhatsApp"
					type="button"
					onClick={selectSecond}
					className="_active btn form-feedback__social-btn whatsapp"
				>
					<img src={WhatsAppSVG} alt="Whatsapp" />
				</button>
			</div>
			<Input
				type="text"
				placeholder="Geben Sie Ihren Namen ein"
				name="name"
				className="form-feedback__input _name"
				onChange={handleChangeName}
				value={name}
			/>
			<Input
				type="text"
				placeholder="Ihre Telefonnummer oder E-Mail"
				name="phone-number"
				className="form-feedback__input _phone-number"
				onChange={handleChange}
				value={phoneNumber}
			/>
			<Textarea
				placeholder="Ihre Nachricht"
				name="message"
				className="form-feedback__textarea"
				onChange={handleChangeMessage}
				value={message}
			/>
			<ButtonPrimary hover type="submit" className="form-feedback__submit">
				Holen Sie sich ein Angebot ein
			</ButtonPrimary>
			<div className="form-feedback__data">
				<input
					checked={isChecked}
					onChange={() => setIsChecked(!isChecked)}
					type="checkbox"
					id="datasecurity"
					className="custom-checkbox feedback-form__checkbox"
				/>
				<label htmlFor="datasecurity">
					<span>
						Durch Klicken auf die Schaltfläche stimmen Sie zu <br /> unterliegen
						den Bestimmungen&nbsp;
						<a href="#">der Datenschutzrichtlinie</a>
					</span>
					<svg viewBox="0,0,50,50">
						<path d="M5 30 L 20 45 L 45 5"></path>
					</svg>
				</label>
			</div>
		</form>
	);
}

export default FeedbackForm;
