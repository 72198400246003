import React, { useState } from "react";

import ItemImg1 from "../../assets/img/clean-services/01.jpg";
import ItemImg2 from "../../assets/img/clean-services/02.jpg";
import ItemImg3 from "../../assets/img/clean-services/03.jpg";
import ItemImg4 from "../../assets/img/clean-services/04.jpg";
import ItemImg5 from "../../assets/img/clean-services/05.jpg";
import ItemImg6 from "../../assets/img/clean-services/06.jpg";
import CleanModal from "./CleanModal";
import { Button } from "react-scroll";

function CleanServices() {
	const [open, setOpen] = useState(false);
	const [titleId, setTitleId] = useState(null);
	const handleOpen = e => {
		const target = e.target;

		setTitleId(target.dataset.id);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	return (
		<div className="clean-services container">
			<div className="clean-services__content">
				<div className="clean-services__header">
					<div className="clean-services__title" id="clean-services">
						Unsere Leistungen im Überblick
					</div>
					<div className="clean-services__subtitle">
						Wir sorgen für Qualitäts- und Kostenorientierte Bewirtschaftung von
						Wohnungsbeständen sowie Wohn-/Geschäftsanlagen und optimieren die
						Gebäudeprozesse für mehr Qualität und weniger Kosten.
					</div>
				</div>
				<div className="clean-services__body">
					<div className="clean-services__item">
						<div className="clean-services__item-top">
							<img
								src={ItemImg1}
								alt="Reinigung"
								className="clean-services__item-img"
							/>
							<div className="clean-services__item-title">Reinigung</div>
							<p className="clean-services__item-text">
								Sauberkeit auf höchstem Niveau! Wir bringen Ihr Zuhause oder
								Büro zum Strahlen. Schnell, zuverlässig und professionell- Ihre
								Reinigungsprofis vor Ort.
							</p>
						</div>
						<div className="clean-services__item-bottom">
							<Button
								onClick={handleOpen}
								className="clean-services__item-btn btn"
								data-id="0"
								data-text="Lassen Sie uns den Schmutz erledigen
								während Sie sich entspannt auf für Sie wesentliches
								konzentrieren. <br /><br /> <h3>Reinigungsarbeiten</h3>
								<ul>
									<li>Nassreinigung von Treppenhaus und Aufzug</li>
									<li>Geländer und Handläufe abwischen</li>
									<li>
										Heizkörper, Fensterbretter, Brüstungen, Schalter,
										Briefkästen feucht abwischen
									</li>
									<li>
										Reinigung der gemeinschaftlichen Türen sowie der Eingangs-,
										Hof- oder Schleusentüren
									</li>
									<li>
										Außen- und Innenreinigung von Fahrstuhlkabinen und -türen
									</li>
									<li>
										Lichtblenden und Beleuchtungskörper demontieren; außen und
										innen reinigen
									</li>
									<li>Glasreinigung inkl. Rahmen</li>
									<li>Kellergänge und gemeinschaftliche Räume</li>
									<li>Reinigung von Büroräumen</li>
									<li>Hausflurreinigung</li>
									<li>Fassadenreinigung</li>
									<li>Hochdruckreinigung</li>
								</ul>"
							>
								Mehr Details
							</Button>
						</div>
					</div>
					<div className="clean-services__item">
						<div className="clean-services__item-top">
							<img
								src={ItemImg2}
								alt="Gartenarbeiten"
								className="clean-services__item-img"
							/>
							<div className="clean-services__item-title">Gartenarbeiten</div>
							<p className="clean-services__item-text">
								Ihr garten in besten Händen! Ob Rasenpflege, Heckenschnitt oder
								Unkrautentfernung - wir kümmern uns um alles. Professionelle
								Gartenarbeiten, die Ihren Außenbereich in voller Pracht
								erstrahlen lassen.
							</p>
						</div>
						<div className="clean-services__item-bottom">
							<Button
								onClick={handleOpen}
								className="clean-services__item-btn btn"
								data-id="1"
								data-text="Ihr garten in besten Händen! Ob Rasenpflege, Heckenschnitt oder
								Unkrautentfernung - wir kümmern uns um alles. Professionelle
								Gartenarbeiten, die Ihren Außenbereich in voller Pracht
								erstrahlen lassen. <br /><br /> <h3>Gartenarbeiten</h3><br />Wir bieten Ihnen einen Komplettservice rund um Ihren Garten und Ihre Grünanlagen.
								<ul>
									<li>Rasenschnitt und -pflege (auch bei Blumenwiesen oder auf Garagendächern)</li>
									<li>Unkrautentfernung</li>
									<li>Heckenschnitt</li>
									<li>Baum- und Gehölzschnitt</li>
									<li>Bewässerung</li>
									<li>Neuanpflanzungen</li>
									<li>Spielplatzkontrolle und Reparatur der Spielgeräte</li>
									<li>Laubentfernung</li>
								</ul>
								"
							>
								Mehr Details
							</Button>
						</div>
					</div>
					<div className="clean-services__item">
						<div className="clean-services__item-top">
							<img
								src={ItemImg3}
								alt="Objektbetreuung"
								className="clean-services__item-img"
							/>
							<div className="clean-services__item-title">Objektbetreuung</div>
							<p className="clean-services__item-text">
								Zuverlässige Objektbetreuung für Ihre Immobilie! Wir bieten
								Rundum-Service vom Reinigung und Instandhaltung bis zu
								Hausmeisterdiensten sowie die Bereitstellung von Abfalltonnen.
							</p>
						</div>
						<div className="clean-services__item-bottom">
							<Button
								onClick={handleOpen}
								className="clean-services__item-btn btn"
								data-id="2"
								data-text=" <h3>Objektbetreuung</h3><br />Ihr Gebäude ist bei uns in den Besten Händen. Sichern Sie sich
								die perfekte Pflege für Ihre Immobilie.
								<ul>
									<li>Überwachung der Hausordnung</li>
									<li>Überwachung des einwandfreien Gesamtzustandes des Objektes und der technischen Anlagen</li>
									<li>Inspektion der gesamten haustechnischen Einrichtungen im Gemeinschaftsbereich</li>
									<li>Überprüfung der elektrischen Einrichtungen, wie Schalter, Klingelanlagen, Beleuchtungen und Sicherungen</li>
									<li>Überprüfung der mechanischen Einrichtungen, wie Türschließautomaten, Zylinder, Federbänder und Schlösser</li>
									<li>Überwachung der zum Anwesen gehörenden Regen-, Fall- und Grundleitungen</li>
								</ul>

								"
							>
								Mehr Details
							</Button>
						</div>
					</div>
					<div className="clean-services__item">
						<div className="clean-services__item-top">
							<img
								src={ItemImg4}
								alt="Kleinreparaturen"
								className="clean-services__item-img"
							/>
							<div className="clean-services__item-title">Kleinreparaturen</div>
							<p className="clean-services__item-text">
								Schnelle Hilfe bei Reparaturen! Egal ob kleiner Reparaturen oder
								begleitende größere Instandsetzungen - Unser Reparaturservice
								bring alles wieder in Schuss.
							</p>
						</div>
						<div className="clean-services__item-bottom">
							<Button
								onClick={handleOpen}
								className="clean-services__item-btn btn"
								data-id="3"
								data-text="Kompetent, zuverlässig und immer
								zur Stelle, wenn es drauf ankommt.<br /><br /> <h3>Kleinreparaturen</h3><br />Wir erledigen für Sie kostengünstig alle Arten von Reparaturen:
								<ul>
									<li>Briefkastenschlösser</li>
									<li>Türzylinder</li>
									<li>Drückergarnituren</li>
									<li>Lampentausch</li>
									<li>Zäune, usw</li>
								</ul>

								"
							>
								Mehr Details
							</Button>
						</div>
					</div>
					<div className="clean-services__item">
						<div className="clean-services__item-top">
							<img
								src={ItemImg5}
								alt="Haushaltsauflösungen"
								className="clean-services__item-img"
							/>
							<div className="clean-services__item-title">
								Haushaltsauflösungen
							</div>
							<p className="clean-services__item-text">
								Haushaltsauflösungen und Sperrgutbeseitigungen leicht gemacht!
								Wir nehmen Ihnen den Stress ab und kümmern uns um alles - von
								der Sortierung bis zur umweltgerechten Entsorgung. Schnell,
								diskret und zuverlässig.
							</p>
						</div>
						<div className="clean-services__item-bottom">
							<Button
								onClick={handleOpen}
								className="clean-services__item-btn btn"
								data-id="4"
								data-text="<h3>Haushaltsauflösungen</h3><br /> Wenn Ihnen z.B nur ein altes Sofa den Plaz für ein Neues versperrt, entsorgen wir es für Sie. <br /> Wenn gewünscht, übernehmen wir auch die abschliessenden Reinigungsarbeiten und übergeben die Räumlichkeiten. <br /><br />
								Wir bieten:
								<ul>
									<li>Container – Bereitstellungen</li>
									<li>Sperrgut – Bereitstellungen</li>
									<li>Entsorgung von Elektroklein- und -großgeräten</li>
									<li>Teil- oder Komplettauflösungen</li>
								</ul>"
							>
								Mehr Details
							</Button>
						</div>
					</div>
					<div className="clean-services__item">
						<div className="clean-services__item-top">
							<img
								src={ItemImg6}
								alt="Homesitting"
								className="clean-services__item-img"
							/>
							<div className="clean-services__item-title">Homesitting</div>
							<p className="clean-services__item-text">
								Ihr Wertvollstes in besten Händen! Mit unserem Homestitting-
								Service ist Ihr Haus auch während Ihrer Abwesenheit gut betreut.
								Ob Pflanzen gießen, Post holen oder einfach nach dem rechten
								sehen.
							</p>
						</div>
						<div className="clean-services__item-bottom">
							<Button
								onClick={handleOpen}
								className="clean-services__item-btn btn"
								data-id="5"
								data-text="Verlassen Sie sich auf uns und genießen sie Ihre Reise unbeschwert.<br/><br/><h3>Homesitting</h3><br /> Wenn Ihnen z.B nur ein altes Sofa den Plaz für ein Neues versperrt, entsorgen wir es für Sie. <br /> Wenn gewünscht, übernehmen wir auch die abschliessenden Reinigungsarbeiten und übergeben die Räumlichkeiten. <br /><br />
								Wir bieten:
								<ul>
									<li>Container – Bereitstellungen</li>
									<li>Sperrgut – Bereitstellungen</li>
									<li>Entsorgung von Elektroklein- und -großgeräten</li>
									<li>Teil- oder Komplettauflösungen</li>
								</ul>"
							>
								Mehr Details
							</Button>
						</div>
					</div>
					<CleanModal open={open} handleClose={handleClose} titleId={titleId} />
				</div>
			</div>
		</div>
	);
}

export default CleanServices;
