import React from "react";
import FeedbackForm from "./FeedbackForm";

function FormComponent() {
	return (
		<section className="feedback-form container-form">
			<div className="feedback-form__content">
				<div className="feedback-form__header">
					<h2 className="feedback-form__title">
						Reinigungskostenrechner mit <span>98 % Genauigkeit</span>
					</h2>
					<div className="feedback-form__subtitle">
						Es ist kostenlos und es gibt keine Verpflichtung!
					</div>
				</div>
				<div className="feedback-form__body">
					<div className="feedback-form__form form-feedback">
						<div className="form-feedback__content">
							<div className="form-feedback__title">
								Großartig! Ihre Antworten sind eingegangen, <br />
								Der Manager beginnt bereits zu zahlen
							</div>
							<div className="form-feedback__subtitle">
								Wo können Sie am besten ein Angebot einholen?
							</div>
							<FeedbackForm />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default FormComponent;
