import React from "react";

function Impressum() {
	return (
		<div className="impressum container">
			<div className="impressum__content">
				<h1 className="impressum__title">Impressum:</h1>
				<pre className="impressum__text">
					HDS Seils & Seils Gebäudeservices GbR <br />
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.google.com/maps?q=Bebbelsdorf+23,+58454+Witten"
					>
						<span className="hover-underline-anim">
							Bebbelsdorf 23, 58454 Witten
						</span>
					</a>
					<br />
					<br />
					Büroanschrift
					<br />
					<br />
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.google.com/maps?q=Oststr.+8,+58452+Witten"
					>
						<span className="hover-underline-anim">
							Oststr. 8, 58452 Witten
						</span>
					</a>
					<br />
					<br />
					<a href="tel:+492302401525" className="hover-underline-anim ">
						02302 / 40 15 25
					</a>
					<br />
					<br />
					Verantwortlich für den Inhalt: Rosalie Seils
					<br />
					Ust.-Id.-Nr.: DE348/5189/2082
					<br />
					<br />
				</pre>
				<div className="impressum__sub-title">Copyright</div>
				<p className="impressum__text">
					Alle Rechte, auch die der fotomechanischen Wiedergabe, der
					Vervielfältigung und der Verbreitung mittels besonderer Verfahren (zum
					Beispiel Datenverarbeitung, Datenträger, Datennetze), auch teilweise,
					behält sich die Firma HDS Seils & Seils Gebäudeservices GbR vor. Für
					etwaige Satzfehler sowie für die Richtigkeit der Eintragungen
					übernimmt die Firma HDS Seils & Seils Gebäudeservices GbR keine
					Gewähr.
				</p>
				<br />
				<br />
				<div className="impressum__sub-title">Markenrecht</div>
				<p className="impressum__text">
					Wir weisen ausdrücklich darauf hin, dass die auf dieser Website
					genannten und gezeigten Bezeichnungen, Warenzeichen, Logos und
					Markennamen Eigentum der entsprechenden Firmen und/oder Personen sind
					und dem Copyright unterliegen!
				</p>
				<br />
				<br />
				<div className="impressum__sub-title">Internet-Bedingungen</div>
				<p className="impressum__text">
					Das Internet arbeitet als dezentraler Netzwerkverbund mit der
					Zwischenspeicherung und Weitergabe temporärer Daten. Eine Gewähr für
					den Ausschluß von Manipulation, von versehentlichem Verfälschen und
					Gewähr für die Verwendung nur aktueller Daten (z.B. alte Version noch
					im Cache) auf dem Verbindungsweg Anbieter zu Nutzer kann es zur Zeit
					daher nicht geben. Auf diese systembedingte Einschränkung weisen wir
					daher wie folgt hin: Sie erhalten diese Daten unter den Bedingungen
					des Internets. Für die Richtigkeit der Angaben und die Übereinstimmung
					mit den Ursprungsdaten kann daher keine Gewähr übernommen werden.
				</p>
				<br />
				<br />
				<div className="impressum__sub-title">Links</div>
				<p className="impressum__text">
					Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden,
					dass mit Ausbringung eines Links die Inhalte der gelinkten Seite ggf.
					mit zu verantworten sind. Dies kann – so das LG – nur dadurch
					verhindert werden, dass man sich von diesen Inhalten ausdrücklich
					distanziert. Wir haben Links zu anderen Seiten im Internet gelegt. Für
					alle diese Links gilt: Wir möchten betonen, dass wir keinerlei
					Einfluss auf die Inhalte oder die Gestaltung der gelinkten Seiten
					haben. Deshalb distanzieren wir uns hiermit ausdrücklich von allen
					Inhalten aller Seiten, die von unseren Seiten verlinkt sind. Die
					Inhalte dieser Seiten machen wir uns nicht zu Eigen. Diese Erklärung
					gilt für alle auf unseren Seiten angebrachten Links und für die
					Inhalte aller Seiten, zu denen die angebrachten Links führen.
				</p>
				<br />
				<br />
				<div className="impressum__date">
					Datenschutz <br />
					<br />
					Diese Website benutzt Google Analytics, einen Webanalysedienst der
					Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
					Textdateien, die auf Ihrem Computer gespeichert werden und die eine
					Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den
					Cookie erzeugten Informationen über Ihre Benutzung dieser Website
					(einschließlich Ihrer IP-Adresse) wird an einen Server von Google in
					den USA übertragen und dort gespeichert. Google wird diese
					Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
					Reports über die Websiteaktivitäten für die Websitebetreiber
					zusammenzustellen und um weitere mit der Websitenutzung und der
					Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird
					Google diese Informationen gegebenenfalls an Dritte übertragen, sofern
					dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
					Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre
					IP-Adresse mit anderen Daten, die von Google gespeichert werden, in
					Verbindung bringen. Sie können die Installation der Cookies durch eine
					entsprechende Einstellung Ihrer Browser Software verhindern; wir
					weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
					nicht sämtliche Funktionen dieser Website voll umfänglich nutzen
					können. Durch die Nutzung dieser Website erklären Sie sich mit der
					Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor
					beschriebenen Art und Weise und zu dem zuvor benannten Zweck
					einverstanden.
				</div>
			</div>
		</div>
	);
}

export default Impressum;
