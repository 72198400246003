// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, EffectCube, Navigation, Pagination } from "swiper/modules";

import React from "react";

function CubeSwiper({ images, className }) {
	const options = {};

	return (
		<>
			<Swiper
				{...options}
				style={{
					"--swiper-pagination-color": "#c62429",
					"--swiper-navigation-color": "#c62429",
				}}
				effect={"cube"}
				grabCursor={true}
				autoplay={{
					delay: 2500,
					disableOnInteraction: true,
				}}
				cubeEffect={{
					shadow: true,
					slideShadows: true,
					shadowOffset: 20,
					shadowScale: 0.94,
				}}
				loop={true}
				navigation={true}
				pagination={{
					clickable: true,
				}}
				modules={[Autoplay, EffectCube, Pagination, Navigation]}
				className={className}
			>
				{images.map(item => {
					return (
						<SwiperSlide className={className + "-slide"} key={item}>
							<img alt={item} src={item} />
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
}

export default CubeSwiper;
