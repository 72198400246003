import classNames from "classnames";
import React from "react";

function Textarea({ onChange, value, name, placeholder, className }) {
	const classes = classNames("textarea", className);
	return (
		<textarea
			className={classes}
			placeholder={placeholder}
			name={name}
			value={value}
			onChange={onChange}
		/>
	);
}

export default Textarea;
