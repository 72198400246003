import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import { Link } from "react-scroll";

function Service() {
	return (
		<>
			<section id="services" className="service container">
				<h3 className="service__title">
					Wie sorgen für Sauberkeit, Sicherheit und Funktionsfähigkeit Ihrer
					Anlagen und Einrichtungen.
				</h3>
				<h3 className="service__subtitle">
					Optimieren die Gebäudeprozesse für mehr Qualität und weniger Kosten
				</h3>
				<div className="service__content">
					<div className="service__item _red">
						<p className="service__text">Hochwertig und neu Ausrüstung</p>
					</div>
					<div className="service__item _black">
						<p className="service__text">
							Hypoallergen sicher Reinigungsmittel
						</p>
					</div>
					<div className="service__item _red">
						<p className="service__text">Wir halten uns daran Sanitär Normen</p>
					</div>
					<div className="service__item _black">
						<p className="service__text">
							Zahlung nach Ergebnis nach dem Saubermachen
						</p>
					</div>
				</div>
			</section>
			<section className="service-category container">
				<div className="service-category__content">
					<Link
						to="clean-services"
						spy={true}
						smooth={true}
						duration={500}
						className="service-category__item"
					>
						<span className="hover-underline-anim hover-underline-anim-mob">
							Dienstleistungen
						</span>
					</Link>

					<a href="#" className="service-category__item ">
						<span className="hover-underline-anim hover-underline-anim-mob">
							Bewertung
						</span>
					</a>
					<LinkRouter
						to="/kalkulieren"
						className="_active service-category__item"
					>
						<span className="hover-underline-anim">
							Berechnen Sie die Kosten
						</span>
					</LinkRouter>
					<a href="#" className="service-category__item ">
						<span className="hover-underline-anim hover-underline-anim-mob">
							Unsere Objekte
						</span>
					</a>
					<a href="#" className="service-category__item ">
						<span className="hover-underline-anim hover-underline-anim-mob">
							Referenz
						</span>
					</a>
				</div>
			</section>
		</>
	);
}

export default Service;
