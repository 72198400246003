import React from "react";

import CubeSwiper from "./UI/CubeSwiper/CubeSwiper";
import CircleIcon from "../assets/img/circle.svg";
import ButtonPrimary from "./UI/Buttons/ButtonPrimary";
import ButtonSecondary from "./UI/Buttons/ButtonSecondary";

import SliderImg1 from "../assets/img/hero-slider/01.jpg";
import SliderImg2 from "../assets/img/hero-slider/02.jpg";
import SliderImg3 from "../assets/img/hero-slider/03.jpg";
import SliderImg4 from "../assets/img/hero-slider/04.jpg";
import SliderImg5 from "../assets/img/hero-slider/05.jpg";
import { Link } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

function Hero() {
	const sliderImgs = [
		SliderImg1,
		SliderImg2,
		SliderImg3,
		SliderImg4,
		SliderImg5,
	];

	return (
		<section className="hero container">
			<div className="hero__content">
				<div className="hero__links">
					<h1 className="hero__title">
						Qualitäts- und Kostenorientierte Bewirtschaftung von
						Wohnungsbeständen und Wohn-/Geschäftsanlagen. Sorgfältige
						Bewirtschaftung und Reinigung von Immobilien im NRW
						<span>mit über 20 Jahren Erfahrung!</span>
					</h1>
					<h2 className="hero__subtitle">
						Wir bieten Ihnen eine optimale Objektbetreuung von der Innen - bis
						zur Außenpflege und sorgen für die haustechnische Überwachung.
					</h2>
					<div className="hero__group-btn">
						<LinkRouter to="/kalkulieren">
							<ButtonPrimary>Berechnen Sie die Kosten</ButtonPrimary>
						</LinkRouter>

						<Link to="clean-services" spy={true} smooth={true} duration={500}>
							<ButtonSecondary>Dienstleistungen auswählen</ButtonSecondary>
						</Link>
					</div>
				</div>
				<div className="hero__right">
					<div className="_stop-scroll">
						<CubeSwiper className="hero-swiper" images={sliderImgs} />
					</div>
					<div className="hero__number">
						<img src={CircleIcon} alt="Circle" className="hero__number-icon" />
						<div className="hero__number-text">
							Mehr <span>als 535</span>
							<br /> Objekte geräumt
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Hero;
