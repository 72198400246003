import classNames from "classnames";
import React from "react";

function ButtonPrimary({ children, className, type, hover, ...props }) {
	const classes = classNames(
		"btn btn-primary _rot",
		className,
		hover ? "_hover2" : ""
	);

	return (
		<button {...props} className={classes} type={type}>
			<span>{children}</span>
		</button>
	);
}

export default ButtonPrimary;
