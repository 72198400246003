import React from "react";
import { Link } from "react-scroll";
import ScrollArrow from "../ScrollArrow/ScrollArrow";
import classNames from "classnames";

function ScrollElem({ to, spy = true, duration = 500, onClick, className }) {
	const classes = classNames("scroll-elem", className);

	return (
		<div className={classes} onClick={onClick}>
			<Link to={to} spy={spy} smooth={true} duration={duration}>
				<ScrollArrow />
			</Link>
		</div>
	);
}

export default ScrollElem;
