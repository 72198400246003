import classNames from "classnames";
import React from "react";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function ButtonAppoitment({ children, className, mob = false }) {
	const classes = classNames("btn btn-appoitment btn-1", className);
	const classesMob = classNames("btn btn-appoitment-mob btn-1", className);

	return mob ? (
		<a href="tel:+492302401525" className={classesMob}>
			<CalendarMonthIcon sx={{ color: "#fff" }} />
		</a>
	) : (
		<a
			href="https://wa.me/492302401525"
			target="_blank"
			rel="noreferrer"
			className={classes}
		>
			{children}
		</a>
	);
}

export default ButtonAppoitment;
