import classNames from "classnames";
import React from "react";

function Input({
	onChange,
	value,
	name,
	type = "text",
	placeholder,
	className,
}) {
	const classes = classNames("input", className);
	return (
		<input
			className={classes}
			type={type}
			placeholder={placeholder}
			name={name}
			value={value}
			onChange={onChange}
		/>
	);
}

export default Input;
