import React, { forwardRef } from "react";
import Video from "../../../assets/video.mp4";

const VideoPlayer = forwardRef((props, ref) => {
	return (
		<video id="player" controls autoPlay ref={ref} loop>
			<source src={Video} type="video/mp4" />
		</video>
	);
});

export default VideoPlayer;
