import classNames from "classnames";
import React from "react";

function ButtonSecondary({ children, className }) {
	const classes = classNames("btn btn-primary _black", className);
	return (
		<button className={classes}>
			<span>{children}</span>
		</button>
	);
}

export default ButtonSecondary;
