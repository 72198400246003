import React from "react";
import ItemImg1 from "../assets/img/our-work/01.png";
import ItemImg2 from "../assets/img/our-work/02.png";
import ItemImg3 from "../assets/img/our-work/03.png";

function OurWork() {
	return (
		<section className="our-work container">
			<div className="our-work__content">
				<div className="our-work__header">
					<div className="our-work__title">
						Unsere Arbeit wird
						<span> auf Professionalität und Ehrlichkeit </span>gebaut
					</div>
					<div className="our-work__subtitle">
						Wenn Sie uns um Hilfe bitten, können Sie es sein Sorgenfreiheit für
						die Qualität und Ihren Komfort
					</div>
				</div>
				<div className="our-work__body">
					<div className="our-work__item">
						<img
							src={ItemImg1}
							alt="Ehrliche Zusammenarbeit"
							className="our-work__item-img"
						/>
						<div className="our-work__item-title">Ehrliche Zusammenarbeit</div>
						<p className="our-work__item-text">
							Wir berechnen die Kosten für die Dienstleistungen im Voraus und
							unsere Zusammenarbeit festigen Vereinbarung. Bezahlung
							ausschließlich nach Fertigstellung. Du überprüfst Ergebnis und
							zahlen, wenn es ist befriedigt dich.
						</p>
					</div>
					<div className="our-work__item">
						<img
							src={ItemImg2}
							alt="Sanfte Reinigungsmittel"
							className="our-work__item-img"
						/>
						<div className="our-work__item-title">Sanfte Reinigungsmittel</div>
						<p className="our-work__item-text">
							Wir gehen die Wahl sorgfältig an Reinigungsmittel. Wir wissen,
							welche Mittel wir im konkreten Fall anwenden müssen Beschädigen
							Sie die Möbel nicht und bewahren Sie sie auf ehemaliger Glanz der
							Oberflächen.
						</p>
					</div>
					<div className="our-work__item">
						<img
							src={ItemImg3}
							alt="Qualitätskontrolle"
							className="our-work__item-img"
						/>
						<div className="our-work__item-title">Qualitätskontrolle</div>
						<p className="our-work__item-text">
							Die Einrichtungen beschäftigen zwei oder mehr erfahrene
							Reinigungskräfte. Die umfassende Reinigung erfolgt normgerecht
							Qualität. Glitzer von der Decke bis zum Boden!{" "}
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default OurWork;
