import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

import CloseIcon from "@mui/icons-material/Close";

import ItemImg1 from "../../assets/img/hero-slider/01.jpg";
import ItemImg2 from "../../assets/img/hero-slider/02.jpg";
import ItemImg3 from "../../assets/img/hero-slider/03.jpg";
import ItemImg4 from "../../assets/img/hero-slider/04.jpg";
import ItemImg5 from "../../assets/img/hero-slider/05.jpg";

import {
	Box,
	IconButton,
	Modal,
	Typography,
	Backdrop,
	Fade,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	maxWidth: "60%", // Установите ширину для ПК
	width: "100%",
	bgcolor: "background.paper",
	borderRadius: 5,
	boxShadow: 24,
	p: 4,
	maxHeight: "90vh", // Ограничение по высоте, чтобы модалка не выходила за экран
	overflowY: "auto", // Включение вертикального скролла при переполнении
	"@media (max-width: 600px)": {
		maxWidth: "90%",
		maxHeight: "80vh",
		p: 3,
	},
};

function CleanModal({ titleId, open, handleClose }) {
	const [text, setText] = useState("");

	const images = [
		[ItemImg1, ItemImg2, ItemImg3, ItemImg4],
		[ItemImg3, ItemImg1, ItemImg2, ItemImg4],
		[ItemImg5, ItemImg2, ItemImg2, ItemImg4],
		[ItemImg2, ItemImg1, ItemImg5, ItemImg4],
		[ItemImg2, ItemImg1, ItemImg2, ItemImg4],
		[ItemImg4, ItemImg3, ItemImg2, ItemImg4],
	];

	useEffect(() => {
		const services = document.querySelectorAll(".clean-services__item");

		console.log(services.length);

		if (services[titleId]) {
			setText(
				services[titleId].querySelector(".clean-services__item-btn").dataset
					.text
			);
		}
	}, [titleId]);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className="clean-modal"
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={open}>
				<Box
					sx={style}
					display="flex"
					alignItems="center"
					flexDirection="column"
					gap="30px"
				>
					<IconButton
						onClick={handleClose}
						sx={{ position: "absolute", right: 20, top: 20 }}
						className="clean-modal-close"
					>
						<CloseIcon />
					</IconButton>

					<Swiper
						modules={[Pagination, Autoplay]}
						className="clean-modal-slider"
						pagination={{
							clickable: true,
							dynamicBullets: true,
						}}
						style={{
							"--swiper-pagination-color": "#c62429",
							"--swiper-navigation-color": "#c62429",
						}}
						autoplay={{
							delay: 2500,
						}}
						loop={true}
						grabCursor
					>
						{images[titleId]
							? images[titleId].map(imgSrc => (
									<SwiperSlide>
										<img src={imgSrc} alt={titleId} />
									</SwiperSlide>
							  ))
							: ""}
					</Swiper>
					<Typography
						id="modal-modal-description"
						className="clean-modal__text"
						dangerouslySetInnerHTML={{ __html: text }}
					></Typography>
					<Link to="/kalkulieren">
						<button className="clean-services__item-btn btn">
							Berechnen Sie die Kosten
						</button>
					</Link>
				</Box>
			</Fade>
		</Modal>
	);
}

export default CleanModal;
