import React from "react";

import RiskImg1 from "../assets/img/risk/01.png";
import RiskImg2 from "../assets/img/risk/02.jpg";
import RiskImg3 from "../assets/img/risk/03.png";
import RiskImg4 from "../assets/img/risk/04.png";

function Risk() {
	return (
		<section className="risk container">
			<div className="risk__content">
				<div className="risk__header">
					<div className="risk__header-know">Wussten Sie?</div>
					<div className="risk__title">
						<span>70 % der Reinigungsunternehmen in NRW</span> unterliegen
						häufig <br /> ihre Kunden vor Risiken und Unannehmlichkeiten.
					</div>
					<div className="risk__subtitle">
						Und hier sind einige Fakten, auf die Sie stoßen könnten: <br />
						Kontaktaufnahme mit anderen Unternehmen:
					</div>
				</div>
				<div className="risk__body">
					<div className="risk__item">
						<img
							src={RiskImg1}
							alt="Täuschung der Endreinigungskosten"
							className="risk__item-img"
						/>
						<div className="risk__item-title">Endreinigungskosten</div>
						<p className="risk__item-text">
							Reinigungsfirmen locken Kunden mit dumping Preisen an. Die
							Unternehmen schweigen über die Kosten. Sie können den höheren
							Preis bezahlen, als vorab kalkulliert. Wir bieten direkt finale
							Preise an, ohne Zusatzkosten!
						</p>
					</div>
					<div className="risk__item">
						<img
							src={RiskImg2}
							alt="Verunreinigung"
							className="risk__item-img"
						/>
						<div className="risk__item-title">Verunreinigung</div>
						<p className="risk__item-text">
							Falsch ausgewählte Reinigungsmittel können Schaden anrichten,
							Oberfläche und Bodenbeläge beschädigen.
						</p>
					</div>
					<div className="risk__item">
						<img
							src={RiskImg3}
							alt="Simulation der Aktivität"
							className="risk__item-img"
						/>
						<div className="risk__item-title">Simulation der Aktivität</div>
						<p className="risk__item-text">
							Reinigungskräfte erledigen oft ihre Aufgaben in böser Absicht. Sie
							reinigen nicht schwer zugängliche Orte und sparen Waschmittel. Als
							Ergebnis, Sie erhalten eine Reinigung von schlechter Qualität und
							schlechte Laune.
						</p>
					</div>
				</div>
				<div className="risk__bottom">
					<div className="risk__bottom-left">
						<img
							src={RiskImg4}
							alt="Verantwortungslosigkeit des Personals"
							className="risk__bottom-left-img"
						/>
						<div className="risk__bottom-left-group">
							<div className="risk__bottom-left-title">
								Verantwortungslosigkeit des Personals
							</div>
							<div className="risk__bottom-left-text">
								Reinigungskräfte kommen früher/später als zur vereinbarten Zeit
								an, was dem Besitzer Unbehagen bereitet. Befolgen Sie keine
								Anweisungen Eigentümer der Wohnung und verstoßen gegen die
								Hausordnung.
							</div>
						</div>
					</div>
					<div className="risk__bottom-right">
						<div className="risk__bottom-right-title">
							Freunde, seid vorsichtig!
						</div>
						<div className="risk__bottom-right-text">
							Bei der Auswahl eines Reinigungsunternehmens und der Bestellung.
							Lesen Sie vor der Reinigung den Vertrag sorgfältig durch für die
							Erbringung von Dienstleistungen
						</div>
						<a href="#" className="risk__bottom-right-link underline">
							Sehen Sie sich den Vertrag an
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Risk;
