import { Route, Routes } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Calculator from "./pages/Calculator";
import ScrollToTop from "./components/ScollToTop/ScrollToTop";
import Impressum from "./pages/Impressum";

function App() {
	return (
		<>
			<ScrollToTop />
			<Layout>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/kalkulieren" element={<Calculator />} />
					<Route path="/impressum" element={<Impressum />} />
				</Routes>
			</Layout>
		</>
	);
}

export default App;
