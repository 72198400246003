import React, { useEffect, useRef } from "react";

import ScrollElem from "../components/UI/ScrollElem/ScrollElem";
import Hero from "../components/Hero";
import Video from "../components/Video";
import Service from "../components/Service";
import FormComponent from "../components/Form/FormComponent";
import Risk from "../components/Risk";
import OurWork from "../components/OurWork";
import CleanServices from "../components/CleanServices/CleanServices";

function Home() {
	const videoPlayer = useRef(null);

	// Funktioniert nicht
	const playVideo = () => {
		console.log(videoPlayer.current);
		videoPlayer.current.play();
	};

	useEffect(() => {
		const links = document.querySelectorAll('a[href="#"]');
		links.forEach(link => {
			link.onclick = event => {
				event.preventDefault();
			};
		});

		// Очищаем обработчики при размонтировании компонента
		return () => {
			links.forEach(link => {
				link.onclick = null;
			});
		};
	}, []);

	return (
		<main className="main">
			<Hero />
			<ScrollElem
				to="video"
				onClick={playVideo}
				className="scroll-elem-hide-mob"
			/>
			<Video />
			<ScrollElem to="service" className="scroll-elem-hide-mob" />
			<Service />

			<FormComponent />
			<CleanServices />
			<Risk />
			<OurWork />
		</main>
	);
}

export default Home;
